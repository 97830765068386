<template>
  <v-container>
    <BaseScreenHeader
      title="Measurements"
      screenInfo="MSR-002"
      width="90%"
      :showBack="false"
      :refresh="true"
      @refresh="refresh"
    />

    <BaseFreeTier v-if="loaded && loadedList.length > 5" />
    <v-card-actions class="px-0">
      <v-spacer />
      <BaseActionButton
        icon="mdi-plus-circle-outline"
        label=""
        text
        class="charcoal paper--text mr-n5"
        large
        @clickedThis="showAddMeasurement = true"
      />
    </v-card-actions>

    <v-card-text class="px-1 pt-0 mb-1">
      <v-text-field
        background-color="charcoal lighten-1"
        class="text-h5"
        height="80"
        solo
        dark
        clearable
        searchCounter
        label="Search Measurements"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        single-line
        hide-details
        autofocus
        @focus="$event.target.select()"
        @input="searchMeasurements"
      />
    </v-card-text>
    <BaseLoading :loaded="loaded" label="Loading" />
    <v-sheet class="charcoal">
      <v-timeline
        dense
        :key="switchNum"
        align-top
        v-if="measurementsList.length > 0"
        :class="isPhone ? 'ml-n6' : ''"
      >
        <v-timeline-item
          color="progressActive"
          fill-dot
          :left="index % 2 == 0"
          :right="index % 2 != 0"
          v-for="(item, index) in measurementsList"
          :key="item.measurementid"
        >
          <template v-slot:icon>
            <v-icon class="link" color="charcoal" @click="handleClick(item)">
              {{ item.icon }}
            </v-icon>
          </template>
          <v-card
            :style="charcoalTile"
            rounded
            class="px-2 ml-n4 mr-1 mt-n2 py-2 charcoal rounded-lg rounded-t-0"
          >
            <v-card-actions class="px-0 py-0">
              <div class="title paper--text">
                {{ item.name }}
              </div>
              <v-spacer />
              <span title="Measurement Type" class="caption paper--text">
                {{ item.measurement_type }}
              </span>
            </v-card-actions>
            <v-card-text
              v-if="Math.floor(item.factor) != 1"
              :class="textSizeSmall + ' progressActive--text pt-0 pb-0 px-0'"
            >
              {{ item.imperial_text }} /
              {{ item.metric_text }}
            </v-card-text>
            <v-card-text
              v-else
              :class="textSizeSmall + ' progressActive--text pt-0 pb-0 px-0'"
            >
              {{ item.imperial_text }}
            </v-card-text>
            <v-card-actions :class="textSizeXSmall + ' pt-0 px-0 silver--text'">
              {{ item.pretty_date }}
              <v-spacer />
            </v-card-actions>
            <v-card-actions
              v-if="item.children != null"
              :class="textSizeXSmall + ' py-2'"
            >
              <v-btn
                :style="charcoalTile"
                color="silver"
                class="pa-0"
                block
                plain
                @click="expandChildren(item)"
              >
                ADDITIONAL MEASUREMENTS
                <v-icon>
                  {{ item.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-text class="silver--text pa-0 ml-4" v-if="item.expanded">
              <div v-for="(child, index) in item.children" :key="index">
                <v-icon color="silver" x-small>{{ child.icon }}</v-icon>
                {{ child.name }}: {{ child.imperial }}
                {{ child.imperial_unit }} / {{ child.metric }}
                {{ child.metric_unit }}
              </div>
            </v-card-text>
            <v-card-actions class="pb-0 pt-4 px-0">
              <BaseActionButton
                :large="!isPhone"
                icon="mdi-trash-can-outline"
                text
                color="silver"
                class="ml-n5"
                label=""
                @clickedThis="showRowDialog(item)"
              />
              <v-spacer />
              <BaseActionButton
                v-if="hasVisuals(item.name)"
                :large="!isPhone"
                icon="mdi-chart-multiple"
                text
                class="mr-n2"
                color="purpleAccent"
                label="Visualize"
                @clickedThis="$router.push('/mystats/bodycomposition')"
              />
            </v-card-actions>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-sheet>

    <AddMeasurement
      :show="showAddMeasurement"
      @done="doneAdding"
      @cancel="showAddMeasurement = false"
    />
    <v-dialog
      v-model="showDeleteDialog"
      :fullscreen="isPhone"
      persistent
      max-width="800"
      overlay-color="#1f2c4c"
      overlay-opacity=".97"
    >
      <v-card
        v-if="selectedMeasurement != null"
        class="d-flex flex-column noscroll charcoal silver--text"
      >
        <BaseCloseDialogHeader
          heading="Delete Measurement?"
          @cancel="showDeleteDialog = false"
        />
        <v-card-text class="px-0">
          <v-card-title class="progressActive--text">
            Your {{ selectedMeasurement.name }}
          </v-card-title>
          <v-card-subtitle :class="textSizeXSmall + ' silver--text pt-5'">
            Entered on
            {{ selectedMeasurement.measured_datetime_local }}.<br />
            Review the information below. Click the
            <span class="progressActive--text">Delete</span> button to remove
            the measurement.
          </v-card-subtitle>
          <v-row dense class="px-2">
            <v-col cols="6">
              <v-text-field
                dark
                label="Imperial"
                disabled
                v-model="selectedMeasurement.imperial_text"
                class="enlarged-input-small"
                prepend-icon="mdi-tape-measure"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                dark
                label="Metric"
                disabled
                v-model="selectedMeasurement.metric_text"
                class="enlarged-input-small"
                prepend-icon="mdi-tape-measure"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="pl-0">
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            color="silver"
            plain
            :large="!isPhone"
            @clickedThis="showDeleteDialog = false"
          />
          <v-spacer />
          <BaseActionButton
            label="Delete"
            text
            class="paper--text"
            :large="!isPhone"
            icon="mdi-trash-can-outline"
            @clickedThis="deleteMeasurement()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import debounce from 'lodash/debounce'

const AddMeasurement = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/AddMeasurement.vue')
export default {
  components: { AddMeasurement },
  name: 'ListMeasurements',
  mixins: [validationMixin, util],
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      switchNum: 0,
      loaded: false,
      isActive: false,
      showAddMeasurement: false,
      showDeleteDialog: false,
      showHelpText: false,
      isUserScrolling: false,
      isNow: true,
      selectedMeasurement: {},
      metric: 0,
      imperial: 0,
      feet: 0,
      inches: 0,
      measured: '',
      search: '',
      selectedMeasure: [],
      measureList: [],
      measurementsList: [],
      loadedList: [],
      datetime: null,
      selectedDateTime: null,
      debouncedScroll: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false,
      reload: false
    }
  },
  beforeMount() {
    this.loadPage()
    this.loadTypes()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },

  watch: {
    bottom(bottom) {
      if (bottom) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.measurementsList.length
    },

    ...appConfig
  },
  methods: {
    expandChildren(item) {
      item.expanded = !item.expanded
    },
    setResults(value) {
      this.measurementsList = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    doneAdding() {
      this.showAddMeasurement = false
      this.currentPage = 0
      this.reload = true
      this.loadPage()
    },
    handleClick(item) {
      if (this.hasVisuals(item.name))
        this.$router.push('/mystats/bodycomposition')
    },
    hasVisuals(name) {
      return (
        name == 'Weight' ||
        name == 'BMI' ||
        name == 'BFP' ||
        name == 'Neck' ||
        name == 'Waist' ||
        name == 'Hip'
      )
    },
    unit(what) {
      if (this.selectedMeasure == '') return ''
      if (what == 'imperial') return ' (' + this.selectedMeasure.imperial + ')'
      return ' (' + this.selectedMeasure.metric + ')'
    },
    showRowDialog(value) {
      this.selectedMeasurement = value
      this.showDeleteDialog = true
    },
    refresh() {
      this.reload = true
      this.currentPage = 0
      this.loadPage()
    },
    loadPage() {
      this.loaded = false
      return axios
        .get(
          this.baseURL +
            '/measurements/scroll/parent/' +
            this.currentPage +
            '/' +
            this.pagesize,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            response.data.data.forEach(element => {
              element.expanded = false
              element.children = JSON.parse(element.children)
            })

            if (this.reload) this.measurementsList = response.data.data
            else
              this.measurementsList = this.measurementsList.concat(
                response.data.data
              )

            this.loadedList = this.measurementsList.slice()
            this.loaded = true
            this.reload = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    calculateImperial() {
      let rounding = Math.pow(10, this.selectedMeasure.rounding)
      this.imperial = this.metric / this.selectedMeasure.factor
      this.feet = Math.floor(this.imperial / 12)
      this.inches = Math.floor(this.imperial % 12)
      this.imperial = Math.floor(this.imperial * rounding) / rounding
    },
    calculateMetric() {
      let rounding = Math.pow(10, this.selectedMeasure.rounding)
      if (this.selectedMeasure.name == 'Height') {
        this.imperial = this.twoDigits(
          parseInt(this.feet * 12) + parseFloat(this.inches)
        )
      } else {
        this.feet = Math.floor(this.imperial / 12)
        this.inches = Math.floor(this.imperial % 12)
      }
      this.metric = this.imperial * this.selectedMeasure.factor
      this.metric = Math.floor(this.metric * rounding) / rounding
    },

    saveMeasurement() {
      let dateObj = new Date(this.datetime)
      this.selectedDateTime = this.formatDateTime(dateObj)
      return axios
        .post(this.baseURL + '/measurements', {
          measurementid: this.selectedMeasure.id,
          metric: this.metric,
          imperial: this.imperial,
          measured: this.isNow ? this.measured : this.selectedDateTime
        })
        .then(response => {
          if (response.status == 200) {
            this.isNow = true
            this.datetime = ''
            this.selectedDateTime = ''
            this.selectedMeasure = {}
            this.showAddMeasurement = false
            this.loadPage()
            this.switchNum++
            this.$store.dispatch(
              'notification/addNotification',
              'Measurement added succesfully',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteMeasurement() {
      return axios
        .delete(
          this.baseURL +
            '/measurement/' +
            this.selectedMeasurement.measurementid +
            ':' +
            this.selectedMeasurement.measurementtypeid
        )
        .then(response => {
          if (response.status == 200) {
            this.selectedMeasurement = {}
            this.showDeleteDialog = false
            this.reload = true
            this.loadPage()
            this.switchNum++
            this.$store.dispatch(
              'notification/addNotification',
              'Measurement deleted succesfully',
              2000,
              true,
              {
                root: true
              }
            )
            window.postMessage('measurement_added', '*')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadTypes() {
      {
        return axios
          .get(this.baseURL + '/measurements/all', {})
          .then(response => {
            if (response.status == 200) {
              this.measureList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    searchMeasurements() {
      {
        if (this.search?.length == 0 || this.search == null) {
          this.reload = true
          this.loadPage()
          return
        }
        this.loaded = false
        return axios
          .get(this.baseURL + '/measurements/search/' + this.search, {})
          .then(response => {
            if (response.status == 200) {
              this.measurementsList = response.data.data
              this.loadedList = this.measurementsList.slice()
              this.loaded = true
              this.reload = false
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
